import { autorun, makeAutoObservable, runInAction } from 'mobx'
import { ethers } from 'ethers'
import { isEmpty } from 'lodash'

import erc20Json from '@/config/abi/ERC20.json'
import { DefaultLendPrice, incentiveDataEnum } from '@/config/constants/assets'
import {
  loadAccountReserveData,
  loadReserve,
  loadUserAccountData,
  loadProjectTokenData,
  loadReserveIncentives,
  loadUiPoolData,
  loadMultiFeeDistribution,
  loadMarketData,
  loadMarketConfiguration,
  loadAccountIncentives,
  loadAccountBaseClaimable,
  loadAccountReserveBalance,
  loadInterestRateStrategyData
} from '@/utils/reserve'
import {
  AccountReserve,
  Price,
  Reserve,
  ProjectToken,
  UserAccount,
  UiPoolData,
  MultiFeeDistribution,
  APR,
  APY,
  AccountReserveAmounts,
  AccountReservesAmounts,
  AccountInfoMantle,
  Market,
  LendingReservesData,
  IncentiveReserve,
  IncentiveAccount,
  IncentiveReserves,
  InterestRateStrategy
} from '@/stores/types'
import { claim } from '@/utils/airdrops'
import { biTokenAmountToReferenceCurrencyAmount, formatBIUnit, formatSymbol } from '@/utils/format'

import { Connector, ConnectorData } from 'wagmi'
import { defaultChainRPC, defaultChainWS, defaultWalletAddress } from '@/config/constants/networks'
import { finOperationsEnum, gasConfig } from '@/config/constants/finOperations'

import { LpUtils } from './utils/lpUtils'
import { getLendPriceFX } from './api'
import RootStore from './index'
import { fetchAccountPositionsData } from './api/subgraphApi'

export default class WalletAccountStore {
  public rootStore: RootStore

  public lpUtils?: LpUtils = {} as LpUtils

  public prices?: Record<Lowercase<string>, Price> = {}

  public projectTokenPrice?: number = 0

  public reservesList: string[] = []

  public lendingReservesData: LendingReservesData = {} as LendingReservesData

  public reserves?: Record<Lowercase<string>, Reserve> = {}

  public markets: Record<Lowercase<string>, Market> = {}

  public accountReserves?: Record<Lowercase<string>, AccountReserve> = {}

  public userAccount?: UserAccount = {} as UserAccount

  public projectToken?: ProjectToken = {} as ProjectToken

  public MFDBalance?: string = ''

  public incentivesReserves: IncentiveReserves = {} as IncentiveReserves

  public incentivesAccount: IncentiveAccount = {} as IncentiveAccount

  public multiFeeDistribution?: MultiFeeDistribution = {} as MultiFeeDistribution

  public uiPoolData?: UiPoolData = {} as UiPoolData

  private provider?: ethers.JsonRpcProvider | ethers.WebSocketProvider = {} as
    | ethers.JsonRpcProvider
    | ethers.WebSocketProvider

  private providerWallet?: ethers.BrowserProvider = {} as ethers.BrowserProvider

  private connector: Connector = {} as Connector

  public isInitAccDataLoadFinished = false

  public isInitReserveDataLoadFinished = false

  public isInitLoadFinished = false

  public lastUpdatedTime: number

  public aPRs: Record<Lowercase<string>, APR> = {}

  public aPYs: Record<Lowercase<string>, APY> = {}

  public activeUserAccountAddress: string = ''

  public hasProvider: boolean = false

  public hasSubgraph: boolean = false

  public accountInfoMantle: AccountInfoMantle = {} as AccountInfoMantle

  public accountReservesAmounts: AccountReservesAmounts = {} as AccountReservesAmounts

  public accountReservesBalances: Record<Lowercase<string>, bigint> = {} as Record<Lowercase<string>, bigint>

  public methApy: number = 0

  public interestRateStrategy: InterestRateStrategy = {} as InterestRateStrategy

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
    this.init()
  }

  async init() {
    const provider = new ethers.JsonRpcProvider(defaultChainRPC)
    // const providerWS = new ethers.WebSocketProvider(defaultChainWS, 5000)

    this.setProvider(provider)

    autorun(() => {
      this.getInitReservesData()
    })
    autorun(() => {
      this.getAssetsPrices()
      this.loadProjectTokenPrice()
    })
    autorun(() => {
      this.setAPRs()
      this.setAPYs()
    })
    autorun(() => {
      this.getInitAccountData()
    })
    autorun(() => {
      this.getMarketsAndReservesUIPool()
    })
    // autorun(() => {
    //   this.getAccountReservesFromUIPool()
    // })
    autorun(() => {
      this.checkProvider()
    })
    autorun(() => {
      this.setAccountReservesBalances()
    })
    autorun(() => {
      this.setAccountReservesAmounts()
    })
    autorun(() => {
      this.setMethApy()
    })

    autorun(() => {
      this.calculateAccountPoints()
    })
  }

  async setProvider(provider: ethers.JsonRpcProvider) {
    runInAction(() => {
      this.provider = provider
    })

    await this.initLpUtils(provider)
  }

  async setWebsocketProvider(provider: ethers.WebSocketProvider) {
    const providerWS = provider
    providerWS.websocket.onopen = () => {
      console.log('Connected to the WS!')

      runInAction(() => {
        this.provider = providerWS
        this.initLpUtils(provider)
        this.watchBlock()
      })
    }
  }

  async initWithWalletConnection(provider: ethers.BrowserProvider, connector: Connector) {
    runInAction(() => {
      this.providerWallet = provider
      this.connector = connector
      this.initLpUtils(provider)
      this.watchConnector()
    })
  }

  async initLpUtils(provider: ethers.BrowserProvider | ethers.JsonRpcProvider | ethers.WebSocketProvider) {
    this.checkProvider()

    if (this.hasProvider) {
      const lpUtils = new LpUtils(provider)
      await lpUtils.setLpConfig()
      runInAction(() => {
        this.lpUtils = lpUtils
      })
    }
  }

  async getInitReservesData() {
    if (isEmpty(this.lpUtils) || this.isInitReserveDataLoadFinished) return

    await this.getUiPoolData()
    await this.getReservesList()
    await this.getLendingReservesData()

    this.getMFDBalance()
    this.getReservesIncentives()
    this.getInterestRateStrategy()

    runInAction(() => {
      this.isInitReserveDataLoadFinished = true
    })
  }

  async getInitAccountData() {
    if (
      isEmpty(this.lpUtils) ||
      isEmpty(this.activeUserAccountAddress) ||
      this.isInitAccDataLoadFinished ||
      isEmpty(this.reservesList) ||
      isEmpty(this.lendingReservesData)
    )
      return

    await Promise.all([
      this.getUiPoolData(),
      this.getAccountIncentives(),
      this.getAccountReserves(),
      this.getUserAccount(),
      this.getProjectToken(),
      this.getMultiFeeDistribution()
    ])

    runInAction(() => {
      this.isInitAccDataLoadFinished = true
      this.lastUpdatedTime = Date.now()
    })
  }

  onDisconnectWallet() {}

  async checkAllowance(erc20Address: string, amount: string | bigint, account: string) {
    this.checkProviderAvailability()

    const runner = await this.providerWallet.getSigner()
    const erc20Contract = new ethers.Contract(erc20Address, erc20Json.abi, runner)
    const { lpAddress } = await this.lpUtils.getLpConfig()

    const responseAllowance = await erc20Contract.allowance(account, lpAddress)
    const amountAllowance = Number(responseAllowance)
    const amountRequest = Number(amount)

    return amountAllowance < amountRequest
  }

  async approve(erc20Address: string, amount: string | bigint) {
    this.checkProviderAvailability()
    const { lpAddress } = await this.lpUtils.getLpConfig()

    const runner = await this.providerWallet.getSigner()
    const erc20Contract = new ethers.Contract(erc20Address, erc20Json.abi, runner)
    const approveResult = await erc20Contract.approve(lpAddress, amount, {
      // maxFeePerGas: ethers.parseUnits(gasConfig.maxFeePerGas, 'gwei'),
      // maxPriorityFeePerGas: ethers.parseUnits(gasConfig.maxPriorityFeePerGas, 'gwei')
    })

    return approveResult
  }

  async checkAllowanceLend(projectTokenAddress: string, amount: string | bigint, account: string) {
    this.checkProviderAvailability()

    const runner = await this.providerWallet.getSigner()
    const erc20Contract = new ethers.Contract(projectTokenAddress, erc20Json.abi, runner)
    const { MultiFeeDistribution } = await this.lpUtils.getLpConfig()

    const responseAllowance = await erc20Contract.allowance(account, MultiFeeDistribution)
    const amountAllowance = Number(responseAllowance)
    const amountRequest = Number(amount)

    return amountAllowance < amountRequest
  }

  async approveLend(projectTokenAddress: string, amount: string | bigint) {
    this.checkProviderAvailability()
    const { MultiFeeDistribution } = await this.lpUtils.getLpConfig()
    const runner = await this.providerWallet.getSigner()
    const projectTokenContract = new ethers.Contract(projectTokenAddress, erc20Json.abi, runner)
    const approveResult = await (projectTokenContract as ethers.Contract).approve(MultiFeeDistribution, amount, {
      // maxFeePerGas: ethers.parseUnits(gasConfig.maxFeePerGas, 'gwei'),
      // maxPriorityFeePerGas: ethers.parseUnits(gasConfig.maxPriorityFeePerGas, 'gwei')
    })
    return approveResult
  }

  async deposit(erc20Address: string, amount: string | bigint, account: string, isMax?: boolean) {
    this.checkProviderAvailability()

    const { lpContract } = await this.lpUtils.getLpConfig()

    const amountMax = this.accountReservesAmounts?.[erc20Address]?.balances.availableToSupply
    const _amount = isMax && amountMax ? amountMax : amount

    const referralCode = 0

    const runner = await this.providerWallet.getSigner()
    const _lpContract = lpContract.connect(runner)
    const depositResult = await (_lpContract as ethers.Contract).deposit(erc20Address, _amount, account, referralCode, {
      // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
    })
    this.updateAccountAmounts()

    return depositResult
  }

  async withdraw(erc20Address: string, amount: string | bigint, account: string, isMax?: boolean) {
    this.checkProviderAvailability()

    const { lpContract } = await this.lpUtils.getLpConfig()

    let _amount = amount

    if (isMax) {
      await this.updateAccountAmounts()
      const amountMax = this.accountReservesAmounts?.[erc20Address]?.balances.availableToWithdraw
      _amount = isMax && amountMax ? amountMax : amount
    }

    const runner = await this.providerWallet.getSigner()
    const _lpContract = lpContract.connect(runner)
    const withdrawResult = await (_lpContract as ethers.Contract).withdraw(erc20Address, _amount, account, {
      // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
    })

    // this.updateAccountAmounts()

    return withdrawResult
  }

  async borrow(erc20Address: string, amount: string | bigint, account: string, isMax?: boolean) {
    this.checkProviderAvailability()

    const { lpContract } = await this.lpUtils.getLpConfig()

    let _amount = amount

    if (isMax) {
      const amountMax = this.accountReservesAmounts?.[erc20Address]?.balances.availableToBorrow
      _amount = amountMax ?? amount
    }

    // 1 - stable, 2 - variable
    const rateMode = 2
    const referralCode = 0

    const runner = await this.providerWallet.getSigner()
    const _lpContract = lpContract.connect(runner)
    const borrowResult = await (_lpContract as ethers.Contract).borrow(
      erc20Address,
      _amount,
      rateMode,
      referralCode,
      account,
      {
        // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
      }
    )

    this.updateAccountAmounts()

    return borrowResult
  }

  async repay(erc20Address: string, amount: string | bigint, account: string, isMax?: boolean) {
    this.checkProviderAvailability()

    const { lpContract } = await this.lpUtils.getLpConfig()

    let _amount = amount

    if (isMax) {
      await this.updateAccountAmounts()
      const amountMax = this.accountReservesAmounts?.[erc20Address]?.balances.availableToRepay
      _amount = isMax && amountMax ? amountMax : amount
    }

    // 1 - stable, 2 - variable
    const rateMode = 2

    const runner = await this.providerWallet.getSigner()
    const _lpContract = lpContract.connect(runner)
    const repayResult = await (_lpContract as ethers.Contract).repay(erc20Address, _amount, rateMode, account, {
      // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
    })

    this.updateAccountAmounts()

    return repayResult
  }

  async getEstimateGas(erc20Address: string, amount: string | bigint, account: string, method: string) {
    const rateMode = 2
    const referralCode = 0
    this.checkProviderAvailability()
    const { lpContract } = await this.lpUtils.getLpConfig()
    const runner = await this.providerWallet.getSigner()
    const _lpContract = lpContract.connect(runner)

    let estimateGas = BigInt(0)

    try {
      if (method === 'approve') {
        const erc20Contract = new ethers.Contract(erc20Address, erc20Json.abi, runner)
        estimateGas = await erc20Contract.approve.estimateGas(lpContract, amount, {
          // maxFeePerGas: ethers.parseUnits(gasConfig.maxFeePerGas, 'gwei'),
          // maxPriorityFeePerGas: ethers.parseUnits(gasConfig.maxPriorityFeePerGas, 'gwei')
        })
      }

      if (method === finOperationsEnum.borrow) {
        estimateGas = await (_lpContract as ethers.Contract).borrow.estimateGas(
          erc20Address,
          amount,
          rateMode,
          referralCode,
          account,
          {
            // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
          }
        )
      }

      if (method === finOperationsEnum.repay) {
        estimateGas = await (_lpContract as ethers.Contract).repay.estimateGas(
          erc20Address,
          amount,
          rateMode,
          account,
          {
            // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
          }
        )
      }

      if (method === finOperationsEnum.deposit) {
        estimateGas = await (_lpContract as ethers.Contract).deposit.estimateGas(
          erc20Address,
          amount,
          account,
          referralCode,
          {
            // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
          }
        )
      }

      if (method === finOperationsEnum.withdraw) {
        estimateGas = await (_lpContract as ethers.Contract).withdraw.estimateGas(erc20Address, amount, account, {
          // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
        })
      }
    } catch (error) {
      return 0
    }

    return Number(ethers.formatUnits(estimateGas, 18)) * Number(gasConfig.gasPrice) * 1000000000
  }

  async claim(account: string | bigint, erc20Address: string | any[]) {
    this.checkProviderAvailability()
    const { lpContract, chefIncentivesControllerContract } = await this.lpUtils.getLpConfig()
    let requestDate = []

    if (typeof erc20Address === 'string') {
      const lendingData = await lpContract.getReserveData(erc20Address)
      const { aTokenAddress, variableDebtTokenAddress } = lendingData
      requestDate = [aTokenAddress, variableDebtTokenAddress]
    } else {
      requestDate = erc20Address
    }

    const runner = await this.providerWallet.getSigner()
    const _chefIncentivesControllerContract = chefIncentivesControllerContract.connect(runner)
    const claimResult = await (_chefIncentivesControllerContract as ethers.Contract).claim(account, requestDate, {
      // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
    })

    return claimResult
  }

  async amountApproved(contract, account, recipientAddress, amount) {
    const responseAllowance = await contract.allowance(account, recipientAddress)
    const approvedAmount = parseInt(responseAllowance, 16)
    const requestedAmount = parseInt(amount, 16)
    if (approvedAmount < requestedAmount) {
      const approveAmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935' // (2^256 - 1)

      const runner = await this.providerWallet.getSigner()
      const _contract = contract.connect(runner)

      try {
        await (_contract as ethers.Contract).approve(recipientAddress, approveAmount)
        return true
      } catch (err) {
        return false
      }
    }
    return true
  }

  async stake(amount: string | bigint, type: boolean) {
    this.checkProviderAvailability()
    const { multiFeeDistributionContract } = await this.lpUtils.getLpConfig()

    const runner = await this.providerWallet.getSigner()
    const _multiFeeDistributionContract = multiFeeDistributionContract.connect(runner)
    const stakeResult = await (_multiFeeDistributionContract as ethers.Contract).stake(amount, type, {
      // gasPrice: ethers.parseUnits(gasConfig.gasPrice, 'gwei')
    })

    return stakeResult
  }

  async setAsCollateral(addressAsset: string, status: boolean) {
    this.checkProviderAvailability()
    const { lpContract } = await this.lpUtils.getLpConfig()

    const runner = await this.providerWallet.getSigner()
    const _lpContract = lpContract.connect(runner)
    const result = await (_lpContract as ethers.Contract).setUserUseReserveAsCollateral(addressAsset, status)

    return result
  }

  async claimWithdraw(amount: string | bigint) {
    this.checkProviderAvailability()
    const { multiFeeDistributionContract } = await this.lpUtils.getLpConfig()

    const runner = await this.providerWallet.getSigner()
    const _multiFeeDistributionContract = multiFeeDistributionContract.connect(runner)
    const result = await (_multiFeeDistributionContract as ethers.Contract).withdraw(amount, {
      // maxFeePerGas: ethers.parseUnits(gasConfig.maxFeePerGas, 'gwei'),
      // maxPriorityFeePerGas: ethers.parseUnits(gasConfig.maxPriorityFeePerGas, 'gwei')
    })

    this.multiFeeDistribution = await this.getMultiFeeDistribution()

    return result
  }

  async withdrawExpiredLocks() {
    this.checkProviderAvailability()
    const { multiFeeDistributionContract } = await this.lpUtils.getLpConfig()

    const runner = await this.providerWallet.getSigner()
    const _multiFeeDistributionContract = multiFeeDistributionContract.connect(runner)
    const result = await (_multiFeeDistributionContract as ethers.Contract).withdrawExpiredLocks({
      // maxFeePerGas: ethers.parseUnits(gasConfig.maxFeePerGas, 'gwei'),
      // maxPriorityFeePerGas: ethers.parseUnits(gasConfig.maxPriorityFeePerGas, 'gwei')
    })

    return result
  }

  async claimExit() {
    this.checkProviderAvailability()
    const { multiFeeDistributionContract } = await this.lpUtils.getLpConfig()

    const runner = await this.providerWallet.getSigner()
    const _multiFeeDistributionContract = multiFeeDistributionContract.connect(runner)
    const exitResult = await (_multiFeeDistributionContract as ethers.Contract).exit(true, {
      // maxFeePerGas: ethers.parseUnits(gasConfig.maxFeePerGas, 'gwei'),
      // maxPriorityFeePerGas: ethers.parseUnits(gasConfig.maxPriorityFeePerGas, 'gwei')
    })

    return exitResult
  }

  async loadProjectTokenPrice() {
    if (isEmpty(this.lpUtils)) return

    const PTPrice = await getLendPriceFX()

    const { projectTokenContract } = await this.lpUtils.getLpConfig()
    if (projectTokenContract) {
      const projectTokenAddress = (await projectTokenContract.getAddress()).toLowerCase()
      const PTPriceRounded = Math.floor(PTPrice * 10000) / 10000

      if (typeof window !== 'undefined') localStorage.setItem(`price_${projectTokenAddress}`, PTPriceRounded.toString())

      runInAction(() => {
        this.prices = { ...this.prices, [projectTokenAddress]: { usd: PTPriceRounded } }
        this.projectTokenPrice = PTPrice
      })
    }
  }

  async getAssetsPrices() {
    const prices: Record<Lowercase<string>, Price> = {}

    if (!isEmpty(this.markets)) {
      Object.entries(this.markets).forEach(([key, value]) => {
        prices[key] = { usd: value.price }
      })
    }

    runInAction(() => {
      this.prices = { ...this.prices, ...prices }
    })

    return prices
  }

  async getReservesList() {
    if (!isEmpty(this.reservesList)) return

    if (!isEmpty(this.uiPoolData)) {
      const reservesList = Object.keys(this.uiPoolData.reserveData)

      runInAction(() => {
        this.reservesList = reservesList
      })

      return reservesList
    }

    const { lpContract } = await this.lpUtils.getLpConfig()
    const reservesList = await lpContract.getReservesList()

    runInAction(() => {
      this.reservesList = reservesList
    })

    return reservesList
  }

  async getReward(allRewards: boolean, rewardsArray: string[]) {
    const { multiFeeDistributionContract } = await this.lpUtils.getLpConfig()
    const runner = await this.providerWallet.getSigner()
    const _multiFeeDistributionContract = multiFeeDistributionContract.connect(runner)
    // const feeData = await this.provider.getFeeData()
    if (allRewards) {
      const stakingRewardsResult = await _multiFeeDistributionContract['getReward()']({
        // gasPrice: feeData.gasPrice
      })
      return stakingRewardsResult
    }
    const stakingRewardsResult = await _multiFeeDistributionContract['getReward(address[])'](rewardsArray, {
      // gasPrice: feeData.gasPrice
    })
    return stakingRewardsResult
  }

  async getUiPoolData() {
    const account = !isEmpty(this.activeUserAccountAddress) ? this.activeUserAccountAddress : defaultWalletAddress

    const { uiPoolDataProviderContract, LendingPoolAddressesProvider } = await this.lpUtils.getLpConfig()
    const uiPoolData = await loadUiPoolData(uiPoolDataProviderContract, LendingPoolAddressesProvider, account)

    console.log('uiPoolData: ', uiPoolData)

    runInAction(() => {
      this.uiPoolData = uiPoolData
    })

    return uiPoolData
  }

  async getMultiFeeDistribution() {
    const { multiFeeDistributionContract } = await this.lpUtils.getLpConfig()
    const multiFeeDistribution: MultiFeeDistribution = await loadMultiFeeDistribution(
      multiFeeDistributionContract,
      this.activeUserAccountAddress
    )

    console.log('multiFeeDistribution:', multiFeeDistribution)
    runInAction(() => {
      this.multiFeeDistribution = multiFeeDistribution
    })

    return multiFeeDistribution
  }

  async getMarketsAndReservesUIPool() {
    if (isEmpty(this.uiPoolData)) return

    const reservesData = this.uiPoolData?.reserveData

    const markets = {} as Record<Lowercase<string>, Market>
    const reserves = {} as Record<Lowercase<string>, Reserve>

    Object.entries(reservesData).forEach(([key, r]) => {
      markets[key.toLowerCase()] = {
        address: r.underlyingAsset,
        decimals: r.decimals,
        symbol: formatSymbol(r.symbol),
        price: Number(r.priceInEth) / 10 ** 18,
        supplied: {
          token: r.underlyingAsset,
          amount: r.availableLiquidity,
          decimals: r.decimals,
          aprPct: Number(r.liquidityRate) / 10 ** 25
        },
        borrowed: {
          token: r.underlyingAsset,
          amount: r.totalScaledVariableDebt,
          decimals: r.decimals,
          aprPct: Number(r.variableBorrowRate) / 10 ** 25
        }
      }
      reserves[key.toLowerCase()] = {
        address: r.underlyingAsset,
        name: formatSymbol(r.symbol),
        amount: r.availableLiquidity,
        available: r.availableLiquidity,
        decimals: r.decimals,
        symbol: formatSymbol(r.symbol),
        price: Number(r.priceInEth) / 10 ** 18,
        supplied: {
          token: r.aTokenAddress,
          amount: r.availableLiquidity,
          decimals: r.decimals,
          aprPct: Number(r.liquidityRate) / 10 ** 25
        },
        borrowed: {
          token: r.variableDebtTokenAddress,
          amount: r.totalScaledVariableDebt,
          decimals: r.decimals,
          aprPct: Number(r.variableBorrowRate) / 10 ** 25
        },
        configuration: {
          address: r.underlyingAsset,
          decimals: r.decimals,
          ltv: r.baseLTVasCollateral,
          liquidationThreshold: r.reserveLiquidationThreshold,
          liquidationBonus: r.reserveLiquidationBonus,
          reserveFactor: r.reserveFactor,
          usageAsCollateralEnabled: r.usageAsCollateralEnabled,
          borrowingEnabled: r.borrowingEnabled,
          stableBorrowRateEnabled: r.stableBorrowRateEnabled
        }
      }
    })

    console.log('markets:', markets)
    console.log('reserves:', reserves)
    runInAction(() => {
      this.markets = markets
      this.reserves = reserves
    })
  }

  async getMarketsAndReserves() {
    const { lpContract, dataContract } = await this.lpUtils.getLpConfig()
    const reservesList = await lpContract.getReservesList()

    const markets = await Promise.all<Market>(
      reservesList.map((reserveAddress: any) => loadMarketData(lpContract, reserveAddress))
    )

    const mappedMarkets: Record<Lowercase<string>, Market> = {}

    markets.forEach((r) => {
      mappedMarkets[r.address?.toLowerCase()] = r
    })

    runInAction(() => {
      this.markets = mappedMarkets
    })

    const marketsConf = await Promise.all(
      reservesList.map((reserveAddress: any) => loadMarketConfiguration(lpContract, dataContract, reserveAddress))
    )

    const mappedReserves: Record<Lowercase<string>, Reserve> = {}

    marketsConf.forEach((r) => {
      mappedReserves[r.address?.toLowerCase()] = {
        ...mappedMarkets[r.address?.toLowerCase()],
        ...r
      }
    })

    runInAction(() => {
      this.reserves = mappedReserves
    })

    return mappedReserves
  }

  async getReserve(reserveAddress: any) {
    const { lpContract, dataContract } = await this.lpUtils.getLpConfig()
    const reserve = await loadReserve(lpContract, dataContract, reserveAddress)

    return reserve
  }

  async getAccountReservesFromUIPool() {
    if (isEmpty(this.uiPoolData) || isEmpty(this.activeUserAccountAddress)) return

    const mappedAccountReserves: Record<Lowercase<string>, AccountReserve> = {}

    const reserves = this.uiPoolData.reserveData
    const accountReserves = this.uiPoolData.userReserve
    const accountAddress = this.activeUserAccountAddress
    const _accountReservesBalances = this.accountReservesBalances

    Object.entries(accountReserves).forEach(([key, r]) => {
      const decimals = Number(reserves[key].decimals)
      const price = reserves[key].priceInEth
      const reserveBalance = reserves[key].availableLiquidity
      const balance = _accountReservesBalances?.[key] ?? 0n
      const _symbol = reserves[key].symbol
      const supplyRate = Number(reserves[key].liquidityRate) / 10 ** 25
      const borrowRate = Number(reserves[key].variableBorrowRate) / 10 ** 25
      const totalSupplied = reserves[key].availableLiquidity
      const totalBorrowed = reserves[key].totalScaledVariableDebt
      const aToken = reserves[key].aTokenAddress
      const bToken = reserves[key].variableDebtTokenAddress
      const supplyPct = Number(r.scaledATokenBalance) / Number(totalSupplied)
      const borrowPct = Number(r.scaledVariableDebt) / Number(totalBorrowed)

      mappedAccountReserves[key] = {
        address: key.toLowerCase(),
        account: accountAddress,
        balance: balance,
        reserveBalance: reserveBalance,
        decimals: decimals,
        priceETH: price,
        price: formatBIUnit(price, 18),
        supplying: {
          token: aToken,
          amount: r.scaledATokenBalance,
          decimals: decimals,
          symbol: formatSymbol(_symbol),
          pct: supplyPct,
          apy: supplyRate
        },
        borrowing: {
          token: bToken,
          amount: r.scaledVariableDebt,
          decimals: decimals,
          symbol: formatSymbol(_symbol),
          pct: borrowPct,
          apy: borrowRate
        }
      }
    })

    runInAction(() => {
      this.accountReserves = mappedAccountReserves
    })

    return mappedAccountReserves
  }

  async getAccountReserves() {
    const account = this.activeUserAccountAddress
    const { lpContract } = await this.lpUtils.getLpConfig()
    const _reservesList = this.reservesList

    const accountReserves = await Promise.all<AccountReserve>(
      _reservesList.map((reserve: string) => loadAccountReserveData(lpContract, reserve, account))
    )
    const mappedAccountReserves: Record<Lowercase<string>, AccountReserve> = {}
    accountReserves.forEach((r) => {
      mappedAccountReserves[r.address?.toLowerCase()] = r
    })

    console.log('accountReserves:', mappedAccountReserves)
    runInAction(() => {
      this.accountReserves = mappedAccountReserves
    })

    return mappedAccountReserves
  }

  async getAccountReserve(reserveAddress: any) {
    const account = this.activeUserAccountAddress
    const { lpContract } = await this.lpUtils.getLpConfig()

    const accountReserve = await loadAccountReserveData(lpContract, reserveAddress, account)

    return accountReserve
  }

  async updateAccountReserve(reserveAddress: string) {
    if (!this.activeUserAccountAddress) return

    const accountReserve = await this.getAccountReserve(reserveAddress)

    runInAction(() => {
      this.accountReserves = { ...this.accountReserves, [reserveAddress.toLowerCase()]: accountReserve }
    })
  }

  async getUserAccount() {
    const account = this.activeUserAccountAddress
    const { lpContract } = await this.lpUtils.getLpConfig()
    const userAccount = await loadUserAccountData(lpContract, account)

    console.log('userAccount:', userAccount)
    runInAction(() => {
      this.userAccount = userAccount
    })

    return userAccount
  }

  async updateAccountAmounts() {
    await this.getAccountReserves()
    // await this.getUiPoolData()
    await this.getUserAccount()
  }

  async updateDataAfterFinOperation(reserveAddress?: string) {
    if (!reserveAddress) {
      await this.getAccountReserves()
      await this.setAccountReservesAmounts()
      await this.setAccountReservesBalances()
    } else {
      await this.updateAccountReserve(reserveAddress)
    }

    // await this.getUiPoolData()
    await this.getUserAccount()
  }

  async setAccountReservesBalances() {
    if (isEmpty(this.reservesList) || isEmpty(this.activeUserAccountAddress) || isEmpty(this.uiPoolData)) return

    const _reservesList = this.reservesList
    const accountAddress = this.activeUserAccountAddress
    const { lpContract } = await this.lpUtils.getLpConfig()

    const accountReservesBalances = await Promise.all<{ address: string; balance: bigint }>(
      _reservesList.map((reserveAddress: string) =>
        loadAccountReserveBalance(lpContract, reserveAddress, accountAddress)
      )
    )

    const mappedAccountReservesBalances: Record<Lowercase<string>, bigint> = {}
    accountReservesBalances.forEach((r) => {
      mappedAccountReservesBalances[r.address.toLowerCase()] = r.balance
    })

    console.log('accountReservesBalances:', mappedAccountReservesBalances)
    runInAction(() => {
      this.accountReservesBalances = mappedAccountReservesBalances
    })
  }

  async setAccountReservesAmounts() {
    if (isEmpty(this.accountReserves) || isEmpty(this.userAccount)) return

    const amounts = Object.keys(this.accountReserves).reduce((acc, key) => {
      const newAcc = acc
      newAcc[key] = this.getAccountReserveAmounts(key)

      return newAcc
    }, {} as AccountReservesAmounts)

    console.log('accountReservesAmounts:', amounts)
    runInAction(() => {
      this.accountReservesAmounts = amounts
    })
  }

  getAccountReserveAmounts(reserveAddress: string) {
    if (isEmpty(this.accountReserves) || isEmpty(this.userAccount)) return {} as AccountReserveAmounts

    const accountReserve: AccountReserve = this.accountReserves?.[reserveAddress]
    const accountData = this.userAccount
    const accountReserveBalance = accountReserve.balance

    const decimals = accountReserve?.decimals
    const price = accountReserve?.price
    const priceETH = accountReserve?.priceETH
    const availableToSupplyTBI = accountReserveBalance
    const borrowedAmountTBI = accountReserve?.borrowing.amount
    const suppliedAmountTBI = accountReserve?.supplying.amount
    const reserveLiquidityTBI = accountReserve?.reserveBalance
    // const availableToBorrowRCBI = (BigInt(accountData?.availableBorrowsETH) / BigInt(10 ** 18)) * BigInt(10 ** decimals)

    const totalDebtRCBI = accountData?.totalDebtETH
    const totalCollateralRCBI = accountData?.totalCollateralETH
    const liqThresholdBI = accountData?.currentLiquidationThreshold

    const usageAsCollateralEnabledOnUser =
      this.uiPoolData.userReserve?.[reserveAddress]?.usageAsCollateralEnabledOnUser ?? null
    let availableToWithdrawTBI

    if (usageAsCollateralEnabledOnUser !== null && usageAsCollateralEnabledOnUser === false) {
      availableToWithdrawTBI = suppliedAmountTBI
    } else {
      const availableToWithdrawRCBI =
        Number(totalDebtRCBI) === 0
          ? totalCollateralRCBI
          : totalCollateralRCBI - (totalDebtRCBI * BigInt(10000)) / liqThresholdBI

      availableToWithdrawTBI =
        suppliedAmountTBI * priceETH >= availableToWithdrawRCBI * BigInt(10 ** decimals)
          ? (availableToWithdrawRCBI * BigInt(10 ** decimals)) / priceETH
          : suppliedAmountTBI
    }

    const availableToRepayTHex =
      BigInt(borrowedAmountTBI) <= BigInt(availableToSupplyTBI) ? borrowedAmountTBI : availableToSupplyTBI

    const availableToBorrowTBI = (accountData.availableBorrowsETH * BigInt(10 ** decimals)) / priceETH

    const availableToBorrowTHex =
      BigInt(availableToBorrowTBI) <= BigInt(reserveLiquidityTBI) ? availableToBorrowTBI : reserveLiquidityTBI

    return {
      balances: {
        supplied: suppliedAmountTBI,
        borrowed: borrowedAmountTBI,
        availableToSupply: availableToSupplyTBI,
        availableToBorrow: availableToBorrowTHex,
        availableToWithdraw: availableToWithdrawTBI,
        availableToRepay: availableToRepayTHex
      },
      decimals: decimals,
      price: price
    } as AccountReserveAmounts
  }

  async getProjectToken() {
    const account = this.activeUserAccountAddress
    const { lpContract, projectTokenContract } = await this.lpUtils.getLpConfig()
    if (projectTokenContract) {
      const projectTokenData = await loadProjectTokenData(lpContract, projectTokenContract, account)

      console.log('projectToken:', projectTokenData)
      runInAction(() => {
        this.projectToken = projectTokenData
      })

      return projectTokenData
    }
  }

  async getMFDBalance() {
    const { projectTokenContract, MultiFeeDistribution } = await this.lpUtils.getLpConfig()

    if (projectTokenContract) {
      const MFDBalanceData = await projectTokenContract.balanceOf(MultiFeeDistribution)

      console.log('MFDBalance:', MFDBalanceData)
      runInAction(() => {
        this.MFDBalance = MFDBalanceData
      })

      return MFDBalanceData
    }
  }

  async setMethApy() {
    try {
      const response: any = await fetch('/api/meth-mantle/apy')
      const responseData = await response.json()
      const { data } = responseData
      if (data && data.length) {
        runInAction(() => {
          this.methApy = Number(data[data.length - 1].FiveDayAPY) * 100
        })
      }
    } catch {
      return 0
    }
  }

  private checkProviderAvailability() {
    if (!this.providerWallet.provider) {
      throw new Error('Provider should be initialized!')
    }
  }

  async getLendingReservesData() {
    if (!isEmpty(this.lendingReservesData)) return

    const lendingReservesData = {} as LendingReservesData

    if (!isEmpty(this.uiPoolData)) {
      Object.entries(this.uiPoolData?.reserveData).forEach(([key, r]) => {
        lendingReservesData[key.toLowerCase()] = {
          aTokenAddress: r.aTokenAddress,
          variableDebtTokenAddress: r.variableDebtTokenAddress
        }
      })

      runInAction(() => {
        this.lendingReservesData = lendingReservesData
      })

      return lendingReservesData
    }

    const { lpContract } = await this.lpUtils.getLpConfig()

    const lendingData = await Promise.all<any>(
      this.reservesList.map(async (reserve: any) => {
        const lendingData = await lpContract.getReserveData(reserve)
        const { aTokenAddress, variableDebtTokenAddress } = lendingData

        return { reserve, aTokenAddress, variableDebtTokenAddress }
      })
    )

    lendingData.forEach((r) => {
      lendingReservesData[r.reserve.toLowerCase()] = {
        aTokenAddress: r.aTokenAddress,
        variableDebtTokenAddress: r.variableDebtTokenAddress
      }
    })

    console.log('lendingReservesData:', lendingReservesData)
    runInAction(() => {
      this.lendingReservesData = lendingReservesData
    })
  }

  async getReservesIncentives() {
    const { chefIncentivesControllerContract } = await this.lpUtils.getLpConfig()

    if (!isEmpty(this.incentivesReserves)) return

    const totalAllocPoint = await chefIncentivesControllerContract.totalAllocPoint()
    const rewardsPerSecond = await chefIncentivesControllerContract.rewardsPerSecond()

    const incentivesData = {
      totalAllocPoint: Number(totalAllocPoint),
      rewardsPerSecond: Number(rewardsPerSecond) / 10 ** 18
    }

    const reservesIncentivesData = await Promise.all<IncentiveReserve>(
      this.reservesList.map(async (reserve: string) => {
        const lendingReserveData = this.lendingReservesData?.[reserve.toLowerCase()]
        const { aTokenAddress, variableDebtTokenAddress } = lendingReserveData

        const reserveIncentivesData = await loadReserveIncentives(
          chefIncentivesControllerContract,
          reserve,
          aTokenAddress,
          variableDebtTokenAddress
        )

        return reserveIncentivesData
      })
    )

    reservesIncentivesData.forEach((r) => {
      incentivesData[r.address?.toLowerCase()] = r
    })

    console.log('incentivesReserves:', incentivesData)
    runInAction(() => {
      this.incentivesReserves = incentivesData
    })

    return incentivesData
  }

  async getAccountIncentives() {
    if (!this.activeUserAccountAddress) return

    const account = this.activeUserAccountAddress

    const { chefIncentivesControllerContract } = await this.lpUtils.getLpConfig()

    const incentivesData = { ...this.incentivesAccount }

    const reserveAccountBaseClaimableData = await loadAccountBaseClaimable(chefIncentivesControllerContract, account)

    const reservesAccountIncentivesData = await Promise.all<IncentiveAccount>(
      this.reservesList.map(async (reserve: string) => {
        const lendingReserveData = this.lendingReservesData?.[reserve.toLowerCase()]
        const { aTokenAddress, variableDebtTokenAddress } = lendingReserveData

        const reserveAccountIncentivesData = await loadAccountIncentives(
          chefIncentivesControllerContract,
          reserve,
          aTokenAddress,
          variableDebtTokenAddress,
          account
        )

        return { ...reserveAccountIncentivesData, ...reserveAccountBaseClaimableData }
      })
    )

    reservesAccountIncentivesData.forEach((r) => {
      incentivesData[r.address?.toLowerCase()] = r
    })

    console.log('incentivesAccount:', incentivesData)
    runInAction(() => {
      this.incentivesAccount = incentivesData
    })

    return incentivesData
  }

  async claimAirdrop(airdropIndex: number) {
    const account = this.activeUserAccountAddress

    return claim(account, this.providerWallet, airdropIndex)
  }

  getProjectTokenPrice(): number {
    return this.projectTokenPrice || Number(DefaultLendPrice) || 0
  }

  getReservePrice(reserveAddress: string): number {
    if (!reserveAddress || !this.reserves) return 0

    const price = this.reserves?.[reserveAddress]?.price || 0

    return price
  }

  setAPRs() {
    if (isEmpty(this.reserves)) return

    const aPRs = {}

    Object.keys(this.reserves).forEach((contractAddress) => {
      aPRs[contractAddress] = this.getAPR(contractAddress)
    })

    runInAction(() => {
      this.aPRs = aPRs
    })
  }

  getAPR(contractAddress: string) {
    if (!this.incentivesReserves?.[contractAddress] || !this.markets?.[contractAddress] || !this.projectTokenPrice) {
      return {
        supply: 0,
        borrow: 0
      }
    }

    const incentive = this.incentivesReserves[contractAddress]
    const market = this.markets?.[contractAddress]
    const price = market?.price || 0

    const tokenPrice = Number(this.projectTokenPrice) || 0
    const { totalAllocPoint, rewardsPerSecond } = this.incentivesReserves
    const { secondsPerYear } = incentiveDataEnum
    const { borrowAllocPoint = 0, supplyAllocPoint = 0 } = incentive
    const totalBorrowedRC =
      biTokenAmountToReferenceCurrencyAmount(market?.borrowed?.amount, price, market.decimals) || 1
    const totalSuppliedRC =
      biTokenAmountToReferenceCurrencyAmount(market?.supplied?.amount, price, market.decimals) || 1

    const rewardSupplyRC = (supplyAllocPoint / totalAllocPoint) * rewardsPerSecond * secondsPerYear * tokenPrice
    const rewardBorrowRC = (borrowAllocPoint / totalAllocPoint) * rewardsPerSecond * secondsPerYear * tokenPrice

    const aprSupply = rewardSupplyRC / (totalSuppliedRC + totalBorrowedRC)
    const aprBorrow = rewardBorrowRC / totalBorrowedRC

    return {
      supply: aprSupply,
      borrow: aprBorrow
    }
  }

  setAPYs() {
    if (isEmpty(this.markets)) return

    const aPYs = {}

    Object.keys(this.markets).forEach((contractAddress) => {
      aPYs[contractAddress] = this.getAPY(contractAddress)
    })

    runInAction(() => {
      this.aPYs = aPYs
    })
  }

  getAPY(contractAddress: string) {
    const aprSupply = this.markets?.[contractAddress]?.supplied.aprPct ?? 0
    const aprBorrow = this.markets?.[contractAddress]?.borrowed.aprPct ?? 0

    return {
      supply: aprSupply,
      borrow: aprBorrow
    }
  }

  updatePrices(newPrice: Record<Lowercase<string>, Price>) {
    const prices = {
      ...this.prices,
      ...newPrice
    }

    runInAction(() => {
      this.prices = prices
    })
  }

  checkProvider() {
    runInAction(() => {
      this.hasProvider = !!this.provider?.provider
    })
  }

  async getProviderSigner() {
    if (!this.providerWallet.provider) return this.provider

    const signer = await this.providerWallet.getSigner()
    return signer
  }

  async getProviderNetwork() {
    return this.provider.getNetwork()
  }

  async watchConnector() {
    if (!this.connector) return

    this.connector.addListener('change', this.listenConnectorChanges)

    const accountAddress = await this.connector.getAccount()

    runInAction(() => {
      this.activeUserAccountAddress = accountAddress ? accountAddress.toLowerCase() : ''
    })
  }

  listenConnectorChanges(data: ConnectorData) {
    runInAction(() => {
      if (data?.account && this.activeUserAccountAddress !== data.account.toLowerCase()) {
        this.activeUserAccountAddress = data.account.toLowerCase()
        this.isInitAccDataLoadFinished = false
        this.getInitAccountData()
      }
    })
  }

  async getAccountInfoMantle(address?: string) {
    const _address = address ?? this.activeUserAccountAddress

    if (!_address) return

    try {
      const response: any = await fetch(`/api/mantlejourney/${_address}`)
      const data = await response.json()

      if (!isEmpty(data)) {
        runInAction(() => {
          this.accountInfoMantle = data
        })
      }
    } catch {
      return 0
    }
  }

  async addERC20Token(
    address: string,
    symbol: string,
    decimals: number,
    chainId: number,
    image?: string
  ): Promise<boolean> {
    const injectedProvider = (window as any).ethereum

    if (injectedProvider) {
      const networkId = Number(injectedProvider.chainId)
      if (address !== '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' && chainId === networkId) {
        try {
          await injectedProvider.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address,
                symbol,
                decimals,
                image
              }
            }
          })

          return true
        } catch (err) {
          return false
        }
      }
      return false
    }
    console.info("Can't add token. No injected provider found")
    return false
  }

  watchBlock() {
    this.provider?.on('block', (blockNumber) => {
      console.log('new block', blockNumber)
    })
  }

  async calculateAccountPoints() {
    const accountAddress = this.activeUserAccountAddress
    const reserves = this.reservesList
    const amountToPoint = 100 // 1 point for every 100 USD
    const lenderMultiplier = 1
    const borrowerMultiplier = 7

    const accountPoints = {} as Record<Lowercase<string>, { supply: number; borrow: number }>

    if (!accountAddress || isEmpty(this.reservesList)) return

    const accountPositionsData = await fetchAccountPositionsData(accountAddress)

    const data = accountPositionsData?.data?.account?.positions

    if (!data) return

    reserves.forEach((reserve) => {
      const reservePoints = {
        supply: 0,
        borrow: 0
      }

      const reservePositions = data
        .filter((position: any) => position.market.id === reserve)
        .sort((a: any, b: any) => b.blockNumberOpened - a.blockNumberOpened)

      if (!reservePositions || reservePositions.length === 0) {
        accountPoints[reserve] = reservePoints

        return
      }

      reservePositions.forEach((position: any) => {
        const decimals = position.market.inputToken.decimals ?? 18
        const price = position.market.inputTokenPriceUSD ?? 0
        const isBorrower = position.side === 'BORROWER'

        let startDay = 0
        let balance = 0
        let points = 0
        let holdDays = 0

        position.snapshots.forEach((s) => {
          holdDays = Math.trunc((s.timestamp - startDay) / 86400)
          points += balance !== 0 ? Math.trunc((balance * price) / amountToPoint) * holdDays : 0

          startDay = s.timestamp
          balance = Number(ethers.formatUnits(s.balance, decimals))
        })

        if (balance > 0 && !position.blockNumberClosed) {
          holdDays = Math.trunc((Date.now() / 1000 - startDay) / 86400)
          points += Math.trunc((balance * price) / amountToPoint) * holdDays
        }

        if (isBorrower) {
          reservePoints.borrow += points * borrowerMultiplier
        } else {
          reservePoints.supply += points * lenderMultiplier
        }
      })

      accountPoints[reserve] = reservePoints
    })

    console.log('accountPoints:', accountPoints)
  }

  async getInterestRateStrategy() {
    if (isEmpty(this.reservesList)) return
    const _reservesList = this.reservesList
    const interestRateStrategyData = await Promise.all(
      _reservesList.map((reserve: string) => {
        const { interestRateStrategyAddress } = this.uiPoolData.reserveData[reserve]
        return loadInterestRateStrategyData(interestRateStrategyAddress, this.provider, reserve)
      })
    )
    const mappedInterestRateStrategy: InterestRateStrategy = {}
    interestRateStrategyData.forEach((r) => {
      const address = r.address?.toLowerCase()
      mappedInterestRateStrategy[address] = r
    })
    runInAction(() => {
      this.interestRateStrategy = mappedInterestRateStrategy
    })
    return mappedInterestRateStrategy
  }
}
