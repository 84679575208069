import { ethers, parseUnits } from 'ethers'
import { decimalsETH, chainId } from '@/config/constants/networks'
import { isNil } from 'lodash'

export const formatUnits = (amount: string | bigint, decimals?: number) => {
  const decimalsAmount = decimals || decimalsETH[chainId]
  if (!amount || amount === 'undefined') {
    return 0
  }

  return parseFloat(ethers.formatUnits(amount, decimalsAmount))
}

export const formatHealthFactor = (amount: string | bigint, totalDebt: string | bigint) => {
  const decimals = decimalsETH[chainId]
  return amount
    ? totalDebt && formatUnits(totalDebt, decimals) !== 0
      ? formatUnits(amount, decimals) < 1000
        ? formatUnits(amount, decimals) < 10
          ? Math.round(formatUnits(amount, decimals) * 100) / 100
          : Math.round(formatUnits(amount, decimals))
        : 999
      : -1
    : -1
}
export const formatCalculatedHealthFactor = (amount: number, totalDebt: number) =>
  amount >= 0
    ? totalDebt
      ? amount < 1000
        ? amount < 10
          ? Math.round(amount * 100) / 100
          : Math.round(amount)
        : 999
      : -1
    : -1

export const toUsd = (
  amount: string | bigint,
  price: number,
  decimals = decimalsETH[chainId],
  prefix = '$ ',
  presision = 2
) =>
  !amount || Number(amount) === 0
    ? `${prefix}0`
    : parseFloat(ethers.formatUnits(amount, decimals)) * price >= 0.01
      ? `${prefix}${(parseFloat(ethers.formatUnits(amount, decimals)) * price).toFixed(presision)}`
      : `$ <${(0.01).toLocaleString()}`

export const toTokenPrice = (amount: string, price: number, decimals = decimalsETH[chainId]) =>
  amount ? `${(parseFloat(ethers.formatUnits(amount, decimals)) / price).toFixed(2)}` : '0,00'

export const toTokenFromEth = (amount: string | bigint, price: number, decimals = decimalsETH[chainId]) =>
  amount ? parseFloat(ethers.formatUnits(amount, decimals)) / price : 0

export const formatSymbol = (symbolStr: string) => {
  let symbol = symbolStr.toUpperCase()
  if (symbol.length > 0 && symbol.slice(0, 2) === 'LV') {
    symbol = symbol.slice(2)
  }
  if (symbol === 'ETH') {
    symbol = 'WETH'
  }
  if (symbol === 'METH') {
    symbol = 'mETH'
  }
  if (symbol === 'BTC') {
    symbol = 'WBTC'
  }
  symbol = symbol.replace('.', '')

  return symbol
}

// Convert a hex string number to decimal number
export const hexToDecimalAmount = (amount: string | bigint, decimals = decimalsETH[chainId]): number =>
  !amount || Number(amount) === 0 ? 0 : parseFloat(ethers.formatUnits(amount, decimals))

// Convert a BigInt number to decimal number
export const formatBIUnit = (amount: bigint, decimals = decimalsETH[chainId]): number =>
  !amount || Number(amount) === 0 ? 0 : parseFloat(ethers.formatUnits(amount, decimals))

// Convert a BigInt number to string
export const formatBIString = (amount: bigint, decimals = decimalsETH[chainId]): string =>
  !amount || Number(amount) === 0 ? '0' : ethers.formatUnits(amount, decimals).toString()

// Convert number to BigInt unit
export const parseBIUnit = (amount: number, decimals = decimalsETH[chainId]): bigint =>
  !amount || Number(amount) === 0 ? BigInt(0) : parseUnits(amount.toString(), decimals)

// Convert a hex string number to decimal number, and return as amount in a current reference currency (usd, euro...)
export const biTokenAmountToReferenceCurrencyAmount = (
  amount: string | bigint,
  price = 1,
  decimals = decimalsETH[chainId]
): number => (!amount || Number(amount) === 0 ? 0 : parseFloat(ethers.formatUnits(amount, decimals)) * Number(price))

// Convert a decimal number of reference currency to decimal amount of token
export const referenceCurrencyAmountToTokenAmount = (amount: number, price = 1): number =>
  !amount || Number(amount) === 0 ? 0 : amount / price

export interface INumberFormatOptions {
  isLocal?: boolean
  minDigits?: number
  maxDigits?: number
  isSymbolHide?: boolean
  roundFloor?: boolean
  roundCeil?: boolean
}

export const numberFormat = (amount: number | string, options: INumberFormatOptions = {}) => {
  const { isLocal = true, minDigits, maxDigits, isSymbolHide, roundFloor, roundCeil } = options

  if (!amount) return '0'

  let num = Number(amount)
  let divider = 1
  let symbol: string
  let result: string
  let minD = 2
  let maxD = 2

  if (Math.abs(num) > 1_000_000) {
    divider = 1_000_000
    symbol = 'M'
  } else if (Math.abs(num) > 1000) {
    divider = 1_000
    symbol = 'K'
  } else if (Math.abs(num) < 1) {
    divider = 1
    symbol = ''

    if (Math.abs(num) <= 0.1) {
      maxD = 4
    }

    if (Math.abs(num) <= 0.001) {
      maxD = 6
    }

    if (Math.abs(num) <= 0.0001) {
      maxD = 8
    }
  }

  if (!isNil(minDigits)) {
    minD = minDigits
  }

  if (!isNil(maxDigits)) {
    maxD = maxDigits
  }

  if (maxDigits === 0) minD = 0

  if (roundFloor && maxD > 0) {
    num = Math.floor(num * 10 ** maxD) / 10 ** maxD
  }

  if (roundCeil && maxD > 0) {
    num = Math.ceil(num * 10 ** maxD) / 10 ** maxD
  }

  if (isSymbolHide) {
    divider = 1
    symbol = ''
  }

  result =
    typeof window !== 'undefined' && isLocal
      ? (num / divider).toLocaleString(undefined, { minimumFractionDigits: minD, maximumFractionDigits: maxD })
      : (num / divider).toFixed(minD)
  result = symbol ? result + symbol : result

  return result
}

export const toShortAddress = (input: string, numberOfFirstPart = 4, numberOfLastPart = 4): string =>
  input
    ? `${input.substring(0, numberOfFirstPart)}...${input.substring(input.length - numberOfLastPart, input.length)}`
    : null

export const numberTo0xHex = (amount: number) => `0x${amount.toString(16)}`

export function truncateDecimal(value: string, decimals: number = 18) {
  const [integerPart, decimalPart] = value.split('.')
  if (decimalPart === undefined || decimalPart.length <= decimals) {
    return value
  }
  const truncatedDecimalPart = decimalPart.slice(0, decimals)
  const truncatedValue = `${integerPart}.${truncatedDecimalPart}`
  return truncatedValue
}

export function bigintToHex(value) {
  if (typeof value !== 'bigint') return null

  return `0x${value.toString(16)}`
}
